import React from 'react';
import {
  CloseButtonProps,
  DraggableDirection,
  IconProps,
  Id,
  Theme,
  toast,
  ToastClassName,
  ToastContainer,
  ToastOptions,
  ToastPosition,
  ToastTransition,
} from 'react-toastify';

export function errorToast(message: string, options: ToastOptions = {}): Id {
  return toast(message, { ...options, type: 'error' });
}

export function infoToast(message: string, options: ToastOptions = {}): Id {
  return toast(message, { ...options, type: 'info' });
}

export function successToast(message: string, options: ToastOptions = {}): Id {
  return toast(message, { ...options, type: 'success' });
}

export function warningToast(message: string, options: ToastOptions = {}): Id {
  return toast(message, { ...options, type: 'warning' });
}

interface ToastProps extends ToastOptions {
  /**
   * Pause the timer when the mouse hover the toast.
   * `Default: true`
   */
  pauseOnHover?: boolean;
  /**
   * Pause the toast when the window loses focus.
   * `Default: true`
   */
  pauseOnFocusLoss?: boolean;
  /**
   * Remove the toast when clicked.
   * `Default: true`
   */
  closeOnClick?: boolean;
  /**
   * Set the delay in ms to close the toast automatically.
   * Use `false` to prevent the toast from closing.
   * `Default: 5000`
   */
  autoClose?: number | false;
  /**
   * Set the default position to use.
   * `One of: 'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left'`
   * `Default: 'top-right'`
   */
  position?: ToastPosition;
  /**
   * Pass a custom close button.
   * To remove the close button pass `false`
   */
  closeButton?:
    | boolean
    | ((props: CloseButtonProps) => React.ReactNode)
    | React.ReactElement<CloseButtonProps>;
  /**
   * An optional css class to set for the progress bar.
   */
  progressClassName?: ToastClassName;
  /**
   * An optional style to set for the progress bar.
   */
  progressStyle?: React.CSSProperties;
  /**
   * An optional css class to set for the toast content.
   */
  bodyClassName?: ToastClassName;
  /**
   * An optional inline style to apply for the toast content.
   */
  bodyStyle?: React.CSSProperties;
  /**
   * Hide or show the progress bar.
   * `Default: false`
   */
  hideProgressBar?: boolean;
  /**
   * Pass a custom transition built with react-transition-group.
   */
  transition?: ToastTransition;
  /**
   * Allow toast to be draggable
   * `Default: true`
   */
  draggable?: boolean;
  /**
   * The percentage of the toast's width it takes for a drag to dismiss a toast
   * `Default: 80`
   */
  draggablePercent?: number;
  /**
   * Specify in which direction should you swipe to dismiss the toast
   * `Default: "x"`
   */
  draggableDirection?: DraggableDirection;
  /**
   * Define the ARIA role for the toast
   * `Default: alert`
   *  https://www.w3.org/WAI/PF/aria/roles
   */
  role?: string;
  /**
   * Set id to handle multiple container
   */
  containerId?: Id;
  /**
   * Fired when clicking inside toaster
   */
  onClick?: (event: React.MouseEvent) => void;
  /**
   * Support right to left display.
   * `Default: false`
   */
  rtl?: boolean;
  /**
   * Used to display a custom icon. Set it to `false` to prevent
   * the icons from being displayed
   */
  icon?:
    | boolean
    | ((props: IconProps) => React.ReactNode)
    | React.ReactElement<IconProps>
    | string
    | number
    | React.ReactNode;
  /**
   * Theme to use.
   * `One of: 'light', 'dark', 'colored'`
   * `Default: 'light'`
   */
  theme?: Theme;
}
export const Toast = ({
  pauseOnHover = true,
  pauseOnFocusLoss = false,
  closeOnClick = true,
  autoClose = 5000,
  position = 'top-center',
  closeButton = true,
  progressClassName,
  progressStyle,
  bodyClassName,
  bodyStyle,
  hideProgressBar = false,
  transition,
  draggable = true,
  draggablePercent = 80,
  draggableDirection = 'x',
  role = 'alert',
  containerId,
  rtl = false,
  icon,
  theme,
  ...props
}: ToastProps) => {
  return (
    <ToastContainer
      pauseOnHover={pauseOnHover}
      pauseOnFocusLoss={pauseOnFocusLoss}
      closeOnClick={closeOnClick}
      autoClose={autoClose}
      position={position}
      closeButton={closeButton}
      progressClassName={progressClassName}
      progressStyle={progressStyle}
      bodyClassName={bodyClassName}
      bodyStyle={bodyStyle}
      hideProgressBar={hideProgressBar}
      transition={transition}
      draggable={draggable}
      draggablePercent={draggablePercent}
      draggableDirection={draggableDirection}
      role={role}
      containerId={containerId}
      rtl={rtl}
      icon={icon}
      theme={theme}
      {...props}
    ></ToastContainer>
  );
};
