import React from 'react';
import { Card } from '../components/cards/card';
import { Anchor } from '../components/typography/anchor';
import { BodyText } from '../components/typography/body-text';
import { Button } from '../components/buttons/button';
import { logout } from '../utils/logout/logout';
import { useRouter } from 'next/router';

export default function Error500Page(): JSX.Element {
  const router = useRouter();
  return (
    <Card
      title="Something Went Wrong!"
      content={
        <>
          It looks like Qeepsake has run into an error! Should this problem
          persist, don't hesitate to reach out to our support team for
          assistance. You can also try signing out and back in to refresh your
          account.
        </>
      }
      footer={
        <div className="flex flex-col" data-testid="500errorContainer">
          <BodyText size="b1" className="text-gray-500">
            Want to contact support?
          </BodyText>
          <Anchor
            href="https://help.qeepsake.com/hc/en-us"
            target="_blank"
            text="Qeepsake Support &rarr;"
          />

          <Button
            data-testid="logoutButton"
            label="Sign Out"
            onClick={() => () => logout(router)}
            additionalClasses="mt-14 mx-auto w-full sm:w-64"
            style="button-primary"
          />
        </div>
      }
    ></Card>
  );
}
