import { setCookie, getCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';

/**
 * Gets the auth token from the request. This will
 * check both the query string and cookies for authentication.
 *
 * @param request - the request.
 * @param response - the response.
 * @param query - the query string.
 *
 * @returns The auth token.
 */
export function getAuthToken(
  request: IncomingMessage,
  response: ServerResponse,
  query?: { auth_token: string },
): string | undefined {
  const authTokenFromQuery = query?.auth_token;
  const authTokenFromCookie = getCookie('auth_token', {
    req: request,
    res: response,
  }) as string;

  if (authTokenFromQuery) {
    setCookie('auth_token', authTokenFromQuery, {
      req: request,
      res: response,
      maxAge: 86400, // 86400 = 24 hours
    });
  }

  // Will prioritise the auth token sent in via the query
  return authTokenFromQuery ?? authTokenFromCookie;
}
