import React from 'react';

export type HeaderSizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface HeaderTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * Font size of the header
   */
  size: HeaderSizes;
  /**
   * Pass in additional tailwind classes.
   * To override font size you have to
   * set the size property to custom
   */
  additionalClasses?: string;
  /**
   * Text string for header
   */
  children: React.ReactNode;
  /**
   * Center text horizontally
   */
  center?: boolean;
}

export const HeaderText = ({
  center = false,
  size,
  additionalClasses,
  children,
  ...props
}: HeaderTextProps) => {
  const isCenter = center ? 'text-center' : '';
  const classes = getClasses(size);

  return (
    <div
      className={[isCenter, `${classes}`, additionalClasses].join(' ')}
      {...props}
    >
      {children}
    </div>
  );

  /**
   * Gets the css classes for the header size
   *
   * @param size - HeaderSizes
   * @returns string of css classes
   */
  function getClasses(size?: HeaderSizes): string {
    switch (size) {
      case 'h2':
        return 'font-feijoa font-bold text-purple-500 text-xl leading-8';
      case 'h3':
        return 'font-feijoa font-bold text-purple-500 text-md leading-6';
      case 'h4':
        return 'font-sohne font-light text-purple-500 text-sm leading-9 tracking-widest';
      case 'h5':
        return 'font-sohne text-gray-500 text-2xs leading-6';
      default:
        return 'font-feijoa font-bold text-purple-500 text-2xl leading-9';
    }
  }
};
