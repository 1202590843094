import * as Sentry from '@sentry/nextjs';
import { ScopeContext } from '@sentry/types';

type CreateParams<S extends string> = {
  name: S;
  message: string;
  level?: `${Sentry.SeverityLevel}`;
  error?: unknown;
  context?: Omit<Partial<ScopeContext>, 'level'>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ErrorResult<C extends string, E = any> {
  public message: string;
  public name: C;
  public error: E;

  constructor(name: C, message: string, error: E) {
    this.name = name;
    this.message = message;
    this.error = error;
  }

  /**
   * Creates an ErrorResult and record the error with the error reporting tool as
   * a side effect.
   *
   * @param params - the parameters for the error
   * @returns error result
   */
  static create<S extends string>({
    name,
    message,
    level = 'error',
    error,
    context = {},
  }: CreateParams<S>): ErrorResult<S> {
    // Captures error when ErrorResult is created.
    let exception;
    if (error instanceof Error) {
      exception = new Error(error.message);
      exception.name = name;
      exception.stack = error.stack;
    } else {
      exception = new Error(message);
      exception.name = name;
    }

    Sentry.captureException(exception, {
      level,
      ...context,
    });

    return new ErrorResult(name, message, error);
  }
}
