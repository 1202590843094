import * as Sentry from '@sentry/nextjs';
import _ from 'lodash';

/**
 * Tracks an Exception
 *
 * @param exception - exception to capture
 * @param severity - log severity level
 * @param additionalInfo - additional information to log
 */
export function trackException(
  exception: Error | unknown,
  severity: Sentry.SeverityLevel = 'warning',
  additionalInfo = {},
): void {
  Sentry.withScope((scope) => {
    scope.setLevel(severity);
    if (additionalInfo) {
      _.map(additionalInfo, function (value, key) {
        scope.setExtra(key, value);
      });
    }

    Sentry.captureException(exception);
  });
}
