import { ApolloClient, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createMemoryCache } from './src/configuration/create-memory-cache';
import clientSchema from './src/graphql/client.gql';

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/graphql`,
});

const getDefaultOptions = () => {
  if (typeof window === 'undefined') {
    //We don't want any cache to be stored server side
    return {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  } else {
    //We immediately show results, but check in the background if any changes occurred, and eventually update the view
    return {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    };
  }
};

const authLink = setContext((request, { headers }) => {
  const token = request.variables.authToken;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token,
      'CF-Access-Client-Id': process.env.NEXT_PUBLIC_CLOUDFLARE_CLIENT_ID,
      'CF-Access-Client-Secret':
        process.env.NEXT_PUBLIC_CLOUDFLARE_CLIENT_SECRET,
    },
  };
});

const resolvers = {
  Query: {
    async book_pages(_, { url }) {
      try {
        const response = await fetch(url);
        const data = await response.json();

        return {
          __typename: 'BookPages',
          ...data,
          pages: data.pages.map((page) => ({
            __typename: 'PageJson',
            ...page,
            fullCoverUrl: page.fullCoverUrl ?? null,
            entries: page.entries.map((entry) => ({
              ...entry,
              dedication: entry.dedication ?? null,
            })),
          })),
        };
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

const typeDefs = gql`
  ${clientSchema}
`;

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: createMemoryCache().restore(global.__APOLLO_STATE__),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
  defaultOptions: getDefaultOptions(),
  ssrMode: typeof window === 'undefined',
  resolvers: resolvers,
  typeDefs: typeDefs,
});

export default client;
