import React from 'react';

interface AnchorProps {
  /**
   * URL for link
   */
  href?: string;

  /**
   * URL for link
   */
  rel?: string;

  /**
   * Target for link
   */
  target?: '_self' | '_blank' | '_parent' | '_top' | 'framename';

  /**
   * Text string for link
   */
  text: string;

  /**
   * Font size of the header
   * based on Tailwind font size
   * https://tailwindcss.com/docs/font-size#setting-the-font-size
   */
  type?: 'default' | 'custom';

  /**
   * Pass in additional tailwind classes.
   * To override font size you have to
   * set the size property to custom
   */
  additionalClasses?: string;

  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  'data-testid'?: string;
}

export const Anchor = ({
  href,
  target = '_self',
  text,
  type = 'default',
  additionalClasses,
  ...props
}: AnchorProps) => {
  const linkStyle =
    type === 'default'
      ? 'font-sohne font-thin text-xs text-gray-500 cursor-pointer underline'
      : 'font-sohne cursor-pointer underline';
  const relationship = target === '_blank' ? 'noreferrer' : '';

  return (
    <a
      className={[linkStyle, additionalClasses].join(' ')}
      {...props}
      href={href}
      rel={relationship}
      target={target}
    >
      {text}
    </a>
  );
};
