import { IFlagsmith } from 'flagsmith/types';
import { ResultAsync } from 'neverthrow';
import { ErrorResult } from '../configuration/error-result';

/**
 * Initialize the Flagsmith SDK
 */
export function initFlagsmith(flagsmith: IFlagsmith) {
  return ResultAsync.fromPromise(
    flagsmith.init({
      environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY || '',
    }),
    (e) => {
      return ErrorResult.create({
        name: 'FEATURE_FLAG_INIT_FAILED',
        message: 'Failed to initialize feature flags.',
        level: 'error',
        error: e,
      });
    },
  );
}
