import React from 'react';
import { HeaderText } from '../typography/header-text';

interface CardProps {
  /**
   * Card title
   * leave blank to omit title
   */
  title?: string;
  /**
   * Card content or body
   * leave blank to omit content
   */
  content?: JSX.Element;
  /**
   * Card footer
   * leave blank to omit footer
   */
  footer?: JSX.Element;
  /**
   * Id for test purposes
   */
  'data-testid'?: string;
}

export const Card = ({ title, content, footer, ...props }: CardProps) => {
  return (
    <div
      className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-beige py-6 sm:py-12"
      {...props}
      data-testid={props['data-testid']}
    >
      <div className="relative bg-white-100 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-500/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <div className="space-y-6 py-8">
          {title && (
            <HeaderText center={true} size="h2">
              {title}
            </HeaderText>
          )}
          {content && <div className="text-center py-6">{content}</div>}
        </div>
        {footer && (
          <div className="text-base font-semibold leading-7">{footer}</div>
        )}
      </div>
    </div>
  );
};
