import React from 'react';

export type BodySizes = 'b1' | 'b2';

interface BodyTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * Font size of the body
   */
  size: BodySizes;
  /**
   * Pass in additional tailwind classes.
   * To override font size you have to
   * set the size property to custom
   */
  additionalClasses?: string;
  /**
   * Text string for subtitle
   */
  children: React.ReactNode;
  /**
   * Center text horizontally
   */
  center?: boolean;
}

export const BodyText = ({
  center = false,
  size,
  additionalClasses,
  children,
  ...props
}: BodyTextProps) => {
  const isCenter = center ? 'text-center' : '';
  const classes = getClasses(size);

  return (
    <div
      className={[isCenter, `${classes}`, additionalClasses].join(' ')}
      {...props}
    >
      {children}
    </div>
  );

  /**
   * Gets the css classes for the body size
   *
   * @param size - BodySizes
   * @returns string of css classes
   */
  function getClasses(size?: BodySizes): string {
    const includesCustomLeading =
      additionalClasses?.includes('leading-') ?? false;

    switch (size) {
      case 'b2': {
        const leading = includesCustomLeading ? '' : 'leading-7';
        return `font-sohne text-black text-base ${leading}`;
      }
      default: {
        return 'font-feijoa text-black text-base leading-6';
      }
    }
  }
};
