import React, { createElement } from 'react';
import * as MultiIcons from '@mui/icons-material';
import ReactLoading from 'react-loading';

interface ButtonProps {
  /**
   * Should the button be full width
   */
  block?: boolean;

  /**
   * Button contents
   */
  label: string;

  /**
   * Button types button|submit|reset
   * defaults to button
   */
  type?: 'button' | 'submit' | 'reset' | undefined;

  /**
   * Button type
   * custom ignores the types and styles the button with
   * the additionalClasses property, if provided
   */
  size?: 'button-small' | 'button-medium' | 'button-large' | 'button-custom';

  /**
   * Pass in additional tailwind classes.
   * To override some classes you may have to
   * set the style or size property to custom
   */
  style?:
    | 'button-primary'
    | 'button-secondary'
    | 'button-custom'
    | 'button-warning';

  /**
   * A variable to determine if the button is in a loading state.
   */
  loading?: boolean;

  /**
   * Color of the loading indicator
   */
  loadingColor?: string;

  /**
   * A custom icon. This can be a string
   * of a Material UI icon: https://mui.com/material-ui/material-icons/
   */
  icon?: keyof typeof MultiIcons;

  /**
   * Button size
   * custom ignores the sizes and styles the button with
   * the additionalClasses property, if provided
   */
  additionalClasses?: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;

  /**
   * Optional disabled state
   * defaults to false
   */
  disabled?: boolean;
}

export const Button = ({
  block = false,
  label,
  size = 'button-medium',
  style = 'button-primary',
  type = 'button',
  loading = false,
  loadingColor = 'white',
  icon,
  additionalClasses,
  disabled = false,
  ...props
}: ButtonProps) => {
  const isBlock = block ? 'w-full' : '';
  const disabledClass = disabled ? 'opacity-50 pointer-events-none' : '';
  const bounceClass =
    'transition duration-200 ease-in-out transform hover:scale-105 active:scale-105';
  const buttonSizeClasses = {
    'button-small': 'text-sm h-8 px-4',
    'button-medium': 'h-9 px-5 max-w-xs',
    'button-large': 'text-lg h-12 px-6 w-full',
    'button-custom': '',
  };
  const buttonStyleClasses = {
    'button-primary':
      'rounded-4xl bg-purple-500 text-white text-white-100 uppercase',
    'button-secondary':
      'rounded-4xl bg-white-100 border-1 border-purple-500 text-purple-500 text-purple-500 uppercase',
    'button-custom': '',
    'button-warning':
      'rounded-4xl bg-red-500 text-white text-white-100 uppercase',
  };
  const buttonSize = buttonSizeClasses[size];
  const buttonStyle = buttonStyleClasses[style];

  return (
    <button
      type={type}
      className={[
        'cursor-pointer transition duration-400',
        isBlock,
        buttonSize,
        buttonStyle,
        bounceClass,
        additionalClasses,
        disabledClass,
      ].join(' ')}
      {...props}
    >
      {!loading ? (
        <div className="flex justify-center items-center">
          <span>{icon ? createElement(MultiIcons[icon]) : null}</span>
          <span className="font-sohne text-xs font-bold tracking-widest">
            {label}
          </span>
        </div>
      ) : (
        <ReactLoading
          type="spinningBubbles"
          color={loadingColor}
          height={20}
          width={20}
          className="mx-auto"
        />
      )}
    </button>
  );
};
